body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-height: 100vh;
  /* margin-left: 280px;
  transition: 0.5s all; */
}
.main {
  flex: 1;
}
.Sidebar {
  top: 65px;
  height: 100vh;
  background-color: #075d19;
  flex: 0.15;
  overflow-y: scroll;
  max-width: 217px;
  min-width: 217px;
  position: fixed;
  z-index: 1299;
}

@media (max-width: 768px) {
  .Sidebar {
    top: 57px;
  }
}

::-webkit-scrollbar {
  display: none;
}
.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
  background-color: transparent !important;
  position: inherit !important;
}

.chatBoxModel {
  width: 100%;
  display: inline-block;
  height: 100vh;
}

.chatBox {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  /* height: 100vh; */
}

.chatBox h1 {
  padding: 0px;
  margin: 0px;
}

.contactList {
  /* height: 100vh; */
  /* border: 2px solid #12d43b; */
  width: 100%;
  height: -webkit-fill-available;
  overflow: auto;
  max-height: 60vh;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 0px;
}

.conversationList {
  height: 93vh;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
}

.chatBarWrapper {
  display: flex;
  flex-grow: 1;
  z-index: 666;
}

.convWrapper {
  display: flex;
  overflow: auto;
  height: 79vh;
}

/* badge styling */
.MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle.MuiBadge-colorPrimary {
  right: auto;
}
/* feed image styling */
.MuiCardMedia-root .makeStyles-media-46 .MuiCardMedia-media .MuiCardMedia-img {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
}
.MuiCardMedia-root {
  object-fit: fill !important;
}

.makeStyles-routes-22 {
  height: 93vh !important;
  overflow: hidden !important;
}

.ql-blank,
.ql-editor {
  min-height: 150px;
}

.ql-tooltip,
.ql-editing {
  left: 144.711px !important;
  top: -19px !important;
}

.parent {
  font-family: arial;
  width: 100%;
  height: 100%;
  position: relative;
}

.child {
  width: 50%;
  height: 20%;
  position: absolute;
  top: 40%;
  left: 35%;
}
@media (max-width: 700px) {
  .parent {
    font-family: arial;
    width: 100%;
    /* height: 100%; */
    position: relative;
  }

  .child {
    width: 100%;
    /* height: 20%; */
    position: absolute;
    top: 5%;
    left: 5%;
  }
}
