* {
  box-sizing: border-box;
}

html,
body {
  height: 100% !important;
}
.mainContainer {
  width: 95%;
  max-width: 95%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  height: 100;
}
.backgroundImage {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
}
.desktopBackImage {
  width: 80%;
  vertical-align: bottom;
  align-items: end;
  height: 80%;
}
.mobileBackImage {
  margin-top: 0.5rem;
  width: 100%;
  vertical-align: bottom;
  align-items: end;
  height: auto;
  display: none;
}
@media (max-width: 958px) {
  .mobileBackImage {
    display: block;
  }
  .desktopBackImage {
    display: none;
  }
}

.main {
  width: 100%;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #e0f1db inset;
  box-shadow: 0 0 0 30px #e0f1db inset;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcomeToDelores {
  font-size: 2.5rem;
  font-weight: bolder;
  line-height: 2.5rem;
  text-decoration: none;
  display: inline-block;
  color: #075d19;
  text-decoration: none;
  text-align: left;
}

.welcomeToDelores:hover {
  text-shadow: 0px 4px 3px rgba(139, 139, 139, 0.4), 0px 8px 13px rgba(129, 129, 129, 0.1),
    0px 18px 23px rgba(121, 121, 121, 0.1);
}

.or-seperator {
  text-align: center;
  color: #075d19;
}

@media (max-width: 430px) {
  .welcomeToDelores {
    font-size: 2.2rem;
    line-height: 1.5rem;
  }
  #round-button {
    width: 100%;
    height: 50px;
    line-height: 0.8;
  }
  .MuiGrid-spacing-xs-3 > .MuiGrid-item {
    padding: 3px 12px !important;
  }
}

.profile {
  width: 100%;
  height: 100vh;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23578e41' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.MuiBox-root.MuiBox-root-1 {
  -webkit-box-shadow: 5px 5px 15px 5px rgb(140 140 140);
  box-shadow: 5px 5px 20px 5px rgb(140 140 140);
}

.MuiTab-wrapper {
  color: black;
}
