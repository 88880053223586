.heading {
  float: center;
  color: #04861f;
  text-decoration: none;
  text-shadow: 0px 4px 3px rgb(119 119 119 / 40%), 0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
  margin: 0.5em;
}

.heading:hover {
  pointer-events: unset;
  text-shadow: 0px 4px 3px rgba(139, 139, 139, 0.4), 0px 8px 13px rgba(129, 129, 129, 0.1),
    0px 18px 23px rgba(121, 121, 121, 0.1);
}
