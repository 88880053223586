.MuiGrid-spacing-xs-2 > .MuiGrid-item {
  padding: 3px !important;
}
@media (max-width: 576px) {
  #main-title h1 {
    font-size: 1.5rem;
    /* line-height: 0.5rem; */
  }
  .link h6 {
    text-align: left;
    font-size: 18px;
  }
  .link p {
    text-align: center;
  }

  #gumircham {
    margin-top: 0 !important;
  }
}

@media (max-width: 430px) {
  .link {
    width: 100%;
    padding: 4px 1rem !important;
  }
}
@media (max-width: 400px) {
  .link {
    width: 100%;
  }
}
